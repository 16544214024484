import axios, { AxiosRequestConfig } from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

const csrfProtectedMethods = ["POST", "PUT", "PATCH", "DELETE"];

const isCsrfProtected = (config: AxiosRequestConfig<any>): boolean => {
  return csrfProtectedMethods.includes(config.method.toUpperCase());
};

const addCsrfTokenRequestInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig<any>> => {
  if (!isCsrfProtected(config)) {
    return config;
  }
  const {
    data: { token, headerName },
  } = await instance.get("/csrf/token", { withCredentials: true });
  config.headers[headerName] = token;
  return config;
};

const errorHandler = (error: AxiosRequestConfig) => {
  return Promise.reject(error);
};

instance.interceptors.request.use(addCsrfTokenRequestInterceptor, errorHandler);

export default instance;
