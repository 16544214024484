import './Confetti.css';

import { useEffect, useState } from 'react';

import Confetti from 'react-confetti';

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0
    });

    useEffect(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setWindowSize]);

    return windowSize;
};

export const USDConfetti: React.FC = () => {
    const { width, height } = useWindowSize();

    return (
        <Confetti
            width={width}
            height={height}
            confettiSource={{ x: width / 2, y: 50, w: 0, h: 0 }}
            initialVelocityX={{ min: -8, max: 8 }}
            className="Confetti"
            recycle={false}
            numberOfPieces={1000}
            tweenDuration={10000}
            colors={[
                '#64a43a',
                '#f18a00',
                '#99d9d9',
                '#0091b3',
                '#005487',
                '#ffbf3c',
                '#ffffff'
            ]}
        />
    );
};
