import "./ApplicationPending.css";

import React, { useEffect, useState } from "react";

import * as Typography from "../components/typography";
import MessagingRow from "../components/MessagingRow";
import ApplicationFooter from "../components/ApplicationFooter";
import { Button } from "../components/Button";
import { TermData as Props } from "../models/TermData";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/store";
import {
  cancelApplicationPost,
  acceptOfferPost,
} from "../store/reducers/applicationInfo";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import { openNewWindow } from "../utility/UtilityFunctions";
import LoadingWheel from "../components/LoadingWheel";

import HeroImageLarge from "../assets/images/banner-1.jpg";
import HeroImageMedium from "../assets/images/banner-1@0.5x.jpg";
import HeroImageSmall from "../assets/images/banner-1@0.25x.jpg";

type CombinedProps = PropsFromRedux & Props & {};

const ApplicationPending: React.FC<CombinedProps> = (props) => {
  const {
    cancelApplicationPost,
    CancelApplicationData,
    applicationNumber,
    termcode,
  } = props;
  const navigate = useNavigate();

  const [showCancelVerification, setShowCancelVerification] = useState(false);
  const [showInvalidAppNumber, setshowInvalidAppNumber] = useState(false);
  const [inputAppNumber, setInputAppNumber] = useState("");

  const declineOffer = (termcode: string) => {
    if (applicationNumber.toString() !== inputAppNumber.trim()) {
      setshowInvalidAppNumber(true);
    } else {
      cancelApplicationPost(termcode);
    }
  };

  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    // Side-effect...

    if (CancelApplicationData.error) {
      toastr.error(
        CancelApplicationData.error.title,
        CancelApplicationData.error.message
      );
    }
    if (CancelApplicationData.cancelApplicationResponse) {
      if (CancelApplicationData.cancelApplicationResponse.declineSurveyUrl) {
        openNewWindow(
          CancelApplicationData.cancelApplicationResponse.declineSurveyUrl
        );
      }
      navigate("/applicationCanceled");
    }

    return () => {
      // Side-effect cleanup...
    };
  }, [
    CancelApplicationData.cancelApplicationResponse,
    CancelApplicationData.loading,
    CancelApplicationData.error,
    navigate,
  ]);

  return (
    <>
      <img
        className="ApplicationPending-heroImage"
        srcSet={`${HeroImageLarge} 3600w, ${HeroImageMedium} 1800w, ${HeroImageSmall} 900w`}
        sizes="(max-width: 600px) 900px, (max-width: 1200px) 1800px, 3600px"
        alt="Application Pending"
      />
      <MessagingRow
        messaging={
          <div className="ApplicationPending-messaging">
            <Typography.PrimaryHeading>
              Your application is pending.
            </Typography.PrimaryHeading>
            <Typography.Paragraph>
              We'll send you an email when the status of your application is
              updated.
            </Typography.Paragraph>
          </div>
        }
        callToAction={
          <>
            {showCancelVerification ? (
              <div className="ApplicationPending-cancellationVerification">
                <Typography.SmallText>
                  Are you sure you want to cancel your application? This action
                  cannot be undone.{" "}
                  <button
                    className="ApplicationPending-cancellationDismissButton"
                    onClick={() => setShowCancelVerification(false)}
                  >
                    Dismiss
                  </button>
                </Typography.SmallText>
                <form
                  className="ApplicationPending-cancellationForm"
                  onSubmit={(e) => {
                    e.preventDefault();
                    declineOffer(termcode);
                  }}
                >
                  <div>
                    <label
                      htmlFor="application-number"
                      className="ApplicationPending-cancellationFormLabel"
                    >
                      Application Number
                    </label>
                    <input
                      className="ApplicationPending-cancellationFormInput"
                      id="appliation-number"
                      name="application-number"
                      type="text"
                      placeholder="0000000"
                      value={inputAppNumber}
                      onChange={(e) => {
                        setInputAppNumber(e.target.value);
                        setshowInvalidAppNumber(false);
                      }}
                    />
                    {showInvalidAppNumber && (
                      <div className="invalid-AppNumber">
                        <label>Invalid Application Number!</label>
                      </div>
                    )}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Button
                      backgroundColor="red"
                      type="submit"
                      disabled={CancelApplicationData.loading === "pending"}
                    >
                      Cancel Your Application
                    </Button>
                    <LoadingWheel
                      numberOfDivs={3}
                      showLoading={CancelApplicationData.loading === "pending"}
                      spinnerOnly={true}
                    />
                  </div>
                </form>
              </div>
            ) : (
              <div className="ApplicationPending-actions">
                <Typography.SmallText>
                  If you'd like to withdraw from the application process, you
                  can{" "}
                  <button
                    className="ApplicationPending-cancellationButton"
                    onClick={() => setShowCancelVerification(true)}
                  >
                    cancel your application
                  </button>
                </Typography.SmallText>
              </div>
            )}
          </>
        }
      />
      <ApplicationFooter {...props} />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    AppTermData: {
      AppTermDataEntity: state.applicationInfo.ApplicationTermsData.entity,
      loading: state.applicationInfo.ApplicationTermsData.loading,
      error: state.applicationInfo.ApplicationTermsData.error,
    },
    CancelApplicationData: {
      cancelApplicationResponse: state.applicationInfo.CancelApplication.entity,
      loading: state.applicationInfo.CancelApplication.loading,
      error: state.applicationInfo.CancelApplication.error,
    },
  };
};

const mapDispatch = {
  cancelApplicationPost,
  acceptOfferPost,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(ApplicationPending);
