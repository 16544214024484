import "./ModalLayout.css";

import React from "react";

interface Props {
  imageSrcSet: string;
  imageSizes: string;
  imageAlt: string;
  children: React.ReactNode;
}

const ModalLayout: React.FC<Props> = (props) => {
  const { imageSrcSet, imageSizes, imageAlt, children } = props;

  return (
    <section className="ModalLayout">
      <img
        className="ModalLayout-image"
        srcSet={imageSrcSet}
        sizes={imageSizes}
        alt={imageAlt}
      />
      <div className="ModalLayout-content">{children}</div>
    </section>
  );
};

export default ModalLayout;
