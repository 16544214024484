import './PrimaryHeading.css';

import React from 'react';

interface Props {
  children: React.ReactNode;
  centered?: boolean;
}

const PrimaryHeading: React.FC<Props> = (props) => {
  const { children, centered = false } = props;

  return (
    <div
      className="PrimaryHeading"
      style={{ textAlign: centered ? 'center' : 'left' }}
    >
      {children}
    </div>
  );
};

export default PrimaryHeading;
