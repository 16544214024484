import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import sir from "../../apis/api";
import { ErrorToastr } from "../../utility/ErrorToastr";
import { CurrentUser } from "../../models/CurrentUser";

export const fetchCurrentUser = createAsyncThunk<CurrentUser>(
    "currentUser",
    async (_, thunkApi) => {
      try {
        const response = await sir.get("/current-user", {
          withCredentials: true,
        });

        if (typeof response.data == "object") {
          return response.data as CurrentUser;
        } else {
          throw new Error("Error - " + response.data);
        }
      } catch (err: any) {
        let error: AxiosError = err; // cast the error for access
        if (!error.response) {
          throw err;
        }

        const meta = { errorMessage: err.message, status: error.response.status };
        const response = { responseMessage: error.response.data, meta: meta };
        return thunkApi.rejectWithValue(response);
      }
    }
  );

 interface UserState {
    error: ErrorToastr | null | undefined;
    loading: "idle" | "pending" | "succeeded" | "failed";
    entity: CurrentUser | null;
 }

  const userSlice = createSlice({
    name: "User",
    initialState: {
      entity: null,
      loading: "idle",
      error: null,
    } as UserState,

    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchCurrentUser.pending, (state, action) => {
          if (state.loading !== "pending") {
            state.loading = "pending";
          }
        })
        .addCase(
            fetchCurrentUser.fulfilled,
          (state, action: PayloadAction<CurrentUser>) => {
            state.loading = "succeeded";
            state.entity = action.payload;
          }
        )
        .addCase(fetchCurrentUser.rejected, (state: any, action: any) => {
          if (action.payload) {
            const errorObj: ErrorToastr = {
              title: "!Error - " + action.payload.meta.status,
              message:
                action.payload && action.payload.responseMessage.message
                  ? action.payload.responseMessage.message
                  : action.payload.meta.errorMessage,
            };
            state.error = errorObj;
          } else {
            const errorObj: ErrorToastr = {
              title: "!Unknown Error",
              message: "Contact Help Desk - " + action.error.message,
            };
            state.error = errorObj;
          }

          state.loading = "failed";
        });
    },
  });

  //export const { removeTermSelected, selectTerm } = userSlice.actions;
  export default userSlice.reducer;
