import "./WelcomeStep.css";

import BackgroundImageLarge from "../assets/images/banner-3.jpg";
import BackgroundImageMedium from "../assets/images/banner-3@0.5x.jpg";
import BackgroundImageSmall from "../assets/images/banner-3@0.25x.jpg";

import React, { useEffect, useState } from "react";

import * as Typography from "../components/typography";
import { Button } from "../components/Button";
import Icons from "../components/Icons";
import { TermData as Props } from "../models/TermData";
import { colorNames } from "../components/Icons";
import { openNewWindow } from "../utility/UtilityFunctions";
import {
  cancelSirPost,
  fetchAdmissionLetter,
} from "../store/reducers/applicationInfo";
import { useNavigate } from "react-router-dom";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/store";
import { toastr } from "react-redux-toastr";
import LoadingWheel from "../components/LoadingWheel";
import ModalLayout from "./ModalLayout";

type CombinedProps = PropsFromRedux & Props & {};

const WelcomeStep: React.FC<CombinedProps> = (props) => {
  const {
    cancelSirPost,
    CancelSirData,
    termcode,
    welcomeLinks,
    admissionsLetterUrl,
    applicationNumber,
    acceptSurveyUrl,
    fetchAdmissionLetter,
    AdmissionLetterData,
  } = props;
  const navigate = useNavigate();

  function randomEnum<T>(anEnum: T): T[keyof T] {
    const enumValues = Object.keys(anEnum)
      .map((n) => Number.parseInt(n))
      .filter((n) => !Number.isNaN(n)) as unknown as T[keyof T][];
    const randomIndex = Math.floor(Math.random() * enumValues.length);
    const randomEnumValue = enumValues[randomIndex];
    return randomEnumValue;
  }

  const getColor = (iconName: string) => {
    switch (iconName) {
      case "globe":
        return "secondary-teal";
      case "map":
        return "accent-orange";
      case "food":
        return "accent-blue";
      case "home":
        return "primary-yellow";
      case "lock":
        return "dark-gray";
      default:
        return randomEnum(colorNames).toString();
    }
  };

  const renderWelcomeLinks = () =>
    welcomeLinks?.map((welcomeLink) => {
      return (
        <>
          {/* eslint-disable-next-line */}
          <a href={welcomeLink.url} target="_blank">
            <Button key={welcomeLink.text}>
              <Icons
                icon={welcomeLink.icon}
                color={getColor(welcomeLink.icon) as any}
              />
              {welcomeLink.text}
            </Button>
          </a>
        </>
      );
    });
  const [showCancelVerification, setShowCancelVerification] = useState(false);
  const [showInvalidAppNumber, setshowInvalidAppNumber] = useState(false);
  const [inputAppNumber, setInputAppNumber] = useState("");

  const cancelSir = (termcode: string) => {
    if (applicationNumber.toString() !== inputAppNumber.trim()) {
      setshowInvalidAppNumber(true);
    } else {
      cancelSirPost(termcode);
    }
  };
  const getAdmissionLetter = (url: string) => {
    fetchAdmissionLetter(url);
  };
  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    // Side-effect...

    if (CancelSirData.error) {
      toastr.error(CancelSirData.error.title, CancelSirData.error.message);
    }

    if (CancelSirData.CancelSirResponse) {
      if (CancelSirData.CancelSirResponse.declineSurveyUrl) {
        openNewWindow(CancelSirData.CancelSirResponse.declineSurveyUrl);
      }
      navigate("/applicationCanceled");
    }
    if (AdmissionLetterData.error) {
      toastr.error(
        AdmissionLetterData.error.title,
        AdmissionLetterData.error.message
      );
    }

    return () => {
      // Side-effect cleanup...
    };
  }, [
    CancelSirData.CancelSirResponse,
    CancelSirData.loading,
    CancelSirData.error,
    AdmissionLetterData.AdmissionLetterResponse,
    AdmissionLetterData.loading,
    AdmissionLetterData.error,
    navigate,
  ]);

  const renderCancelDialog = () => {
    return (
      <>
        {showCancelVerification ? (
          <div className="ApplicationPending-cancellationVerification">
            <Typography.SmallText>
              Are you sure you want to cancel your application? This action
              cannot be undone.{" "}
              <button
                className="ApplicationPending-cancellationDismissButton"
                onClick={() => setShowCancelVerification(false)}
              >
                Dismiss
              </button>
            </Typography.SmallText>
            <form
              className="ApplicationPending-cancellationForm"
              onSubmit={(e) => {
                e.preventDefault();
                cancelSir(termcode);
              }}
            >
              <div>
                <label
                  htmlFor="application-number"
                  className="ApplicationPending-cancellationFormLabel"
                >
                  Application Number
                </label>
                <input
                  className="ApplicationPending-cancellationFormInput"
                  id="appliation-number"
                  name="application-number"
                  type="text"
                  placeholder="0000000"
                  value={inputAppNumber}
                  onChange={(e) => {
                    setInputAppNumber(e.target.value);
                    setshowInvalidAppNumber(false);
                  }}
                />
                {showInvalidAppNumber && (
                  <div className="invalid-AppNumber">
                    <label>Invalid Application Number!</label>
                  </div>
                )}
              </div>
              <div style={{ position: "relative" }}>
                <Button
                  backgroundColor="red"
                  type="submit"
                  disabled={CancelSirData.loading === "pending"}
                >
                  Cancel Your Application
                </Button>
                <LoadingWheel
                  numberOfDivs={3}
                  showLoading={CancelSirData.loading === "pending"}
                  spinnerOnly={true}
                />
              </div>
            </form>
          </div>
        ) : (
          <div className="ApplicationPending-actions">
            <Typography.SmallText>
              Please take a moment to answer a few questions.{" "}
              <a style={{ textDecoration: "underline" }} href={acceptSurveyUrl}>
                Click Here
              </a>
              . If you'd like to withdraw from the application process, you can{" "}
              <button
                className="ApplicationPending-cancellationButton"
                onClick={() => setShowCancelVerification(true)}
              >
                cancel your application
              </button>
              .
            </Typography.SmallText>
          </div>
        )}
      </>
    );
  };
  return (
    <ModalLayout
      imageSrcSet={`${BackgroundImageLarge} 3600w, ${BackgroundImageMedium} 1800w, ${BackgroundImageSmall} 900w`}
      imageSizes="(max-height: 300px) 900px, (max-height: 600px) 1800w, 3600w"
      imageAlt="Welcome to UC Merced!"
    >
      <Typography.PrimaryHeading centered>
        It's official!
      </Typography.PrimaryHeading>
      <div>
        Your Statement of Intent to Register has been processed. Welcome to the
        UC Merced community!
      </div>
      {renderWelcomeLinks()}
      {admissionsLetterUrl && (
        <>
          <Button
            loading={
              AdmissionLetterData.loading === "pending" ? "true" : "false"
            }
            onClick={() => getAdmissionLetter(admissionsLetterUrl)}
          >
            <Icons icon="download" color="secondary-teal" />
            Download Admissions Letter
          </Button>
          <div style={{ marginBottom: "5px" }}></div>
        </>
      )}

      {renderCancelDialog()}
    </ModalLayout>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    AppTermData: {
      AppTermDataEntity: state.applicationInfo.ApplicationTermsData.entity,
      loading: state.applicationInfo.ApplicationTermsData.loading,
      error: state.applicationInfo.ApplicationTermsData.error,
    },
    CancelSirData: {
      CancelSirResponse: state.applicationInfo.CancelSir.entity,
      loading: state.applicationInfo.CancelSir.loading,
      error: state.applicationInfo.CancelSir.error,
    },
    AdmissionLetterData: {
      AdmissionLetterResponse:
        state.applicationInfo.DownloadAdmissionLetter.entity,
      loading: state.applicationInfo.DownloadAdmissionLetter.loading,
      error: state.applicationInfo.DownloadAdmissionLetter.error,
    },
  };
};

const mapDispatch = {
  cancelSirPost,
  fetchAdmissionLetter,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(WelcomeStep);
