import { reducer as toastrReducer } from "react-redux-toastr";
import applicationInfoReducer from "./applicationInfo";
import userReducer from "./user";

const rootReducer = {
  toastr: toastrReducer,
  applicationInfo: applicationInfoReducer,
  user: userReducer
};

export default rootReducer;
