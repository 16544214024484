import "./ApplicationFooter.css";

import React, {useCallback} from "react";

import * as Typography from "./typography";
import { Button } from "./Button";
import Icons from "./Icons";
import ContentWrapper from "./ContentWrapper";
import SignOutButton from "./SignOutButton";
import { TermData } from "../models/TermData";
import { useNavigate } from "react-router-dom";

import { removeTermSelected } from "../store/reducers/applicationInfo";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/store";

type CombinedProps = PropsFromRedux &
  TermData & {
    noApplications?: boolean;
    showTuitionAndWue?: boolean;
  };

const ApplicationFooter: React.FC<CombinedProps> = (props) => {
  const {
    name,
    nickname,
    mailingAddress,
    physicalAddress,
    admissionTerm,
    degree,
    major,
    residency,
    isWueOffered,
    wueOfferUrl,
    undeterminedResidencyUrl,
    requiredDocuments,
    removeTermSelected,
    admissionsEmail,
    admissionsPhoneNumber,
    noApplications,
    showTuitionAndWue,
    AppTermData,
  } = props;

  const navigate = useNavigate();

  const renderResidencyFees = useCallback(() => {
    switch (residency) {
      case "Resident":
        return "California Resident Fees";

      case "Nonresident":
        return "Nonresident Fees";

      case "Undetermined":
        return <a className="stylized" href={undeterminedResidencyUrl}>Click to Submit SLR</a>;

      default:
        return null;
    }
  }, [residency, undeterminedResidencyUrl])

  const gotoSelectTerm = () => {
    removeTermSelected(null);
    navigate("/selectTerm");
  };

  return (
    <>
      <section className="ApplicationFooter">
        <ContentWrapper>
          <div className="ApplicationFooter-contactInfo">
            <Typography.SmallText>
              If you have any questions about your application, please contact
              the Office of Admissions via the Students First Center:
            </Typography.SmallText>
            {admissionsPhoneNumber && (
              <a
                href={
                  "tel:+1" +
                  admissionsPhoneNumber
                    .replace("(", "")
                    .replace(")", "")
                    .replace(" ", "")
                    .replace("-", "")
                }
              >
                <Button>
                  <Icons icon="phone" color="secondary-teal" />
                  {admissionsPhoneNumber}
                </Button>
              </a>
            )}
            <a href={"mailto:" + admissionsEmail}>
              <Button>
                <Icons icon="mail" color="secondary-teal" />
                Email Us
              </Button>
            </a>
          </div>
          {!noApplications && (
            <div className="ApplicationFooter-summaryAndDocs">
              <div className="ApplicationFooter-summary">
                <div className="ApplicationFooter-boxHeading">
                  <Typography.Label centered>
                    Application Summary
                  </Typography.Label>
                </div>
                <div className="ApplicationFooter-summaryRows">
                  <ApplicationSummaryRow label="Name" value={name} />
                  {showTuitionAndWue && (
                    <>
                      <ApplicationSummaryRow
                        label="Tuition Fees"
                        value={renderResidencyFees()}
                      />
                      {(isWueOffered && residency !== 'Resident') && (
                        <ApplicationSummaryRow
                          label="WUE Program"
                          value={<a className="stylized" href={wueOfferUrl}>Offered</a>}
                        />
                      )}
                    </>
                  )}
                  {mailingAddress && (
                    <ApplicationSummaryRow
                      label="Mailing Address"
                      value={
                        <>
                          <div>{mailingAddress.address1}</div>
                          {mailingAddress.address2 && (
                            <div>{mailingAddress.address2}</div>
                          )}
                          <div>
                            {mailingAddress.city}, {mailingAddress.state}{" "}
                            {mailingAddress.zip}
                          </div>
                        </>
                      }
                    />
                  )}
                  {physicalAddress && (
                    <ApplicationSummaryRow
                      label="Physical Address"
                      value={
                        <>
                          <div>{physicalAddress.address1}</div>
                          {physicalAddress.address2 && (
                            <div>{physicalAddress.address2}</div>
                          )}
                          <div>
                            {physicalAddress.city}, {physicalAddress.state}{" "}
                            {physicalAddress.zip}
                          </div>
                        </>
                      }
                    />
                  )}
                  <ApplicationSummaryRow
                    label="Admission Term"
                    value={admissionTerm}
                  />
                  <ApplicationSummaryRow label="Degree" value={degree} />
                  <ApplicationSummaryRow label="Major" value={major} />
                </div>
              </div>
              {requiredDocuments && (
                <div className="ApplicationFooter-docs">
                  <div className="ApplicationFooter-boxHeading">
                    <Typography.Label centered>
                      Required Documents
                    </Typography.Label>
                  </div>
                  <div className="ApplicationFooter-docsBody">
                    <Typography.SmallText>
                      These documents are due to the Office of Admissions by{" "}
                      <strong>
                        {new Date(requiredDocuments.dueDate).toLocaleDateString(
                          undefined,
                          {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                      </strong>
                      .
                    </Typography.SmallText>
                    {requiredDocuments.documents.map((doc, index) => (
                      <div
                        className={
                          doc.received
                            ? "ApplicationFooter-docItem --is-received"
                            : "ApplicationFooter-docItem --is-outstanding"
                        }
                        key={index}
                      >
                        <div className="ApplicationFooter-docLabel">
                          {doc.label}
                        </div>
                        {doc.description && (
                          <Typography.SmallText>
                            {doc.description}
                          </Typography.SmallText>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </ContentWrapper>
      </section>
      <nav className="ApplicationFooter-linkWrapper">
        <ContentWrapper>
          <div className="ApplicationFooter-links">
            {/* eslint-disable-next-line */}
            {AppTermData.AppTermDataEntity &&
              AppTermData.AppTermDataEntity.terms.length > 1 && (
                <a
                  className="ApplicationFooter-backLink"
                  onClick={() => gotoSelectTerm()}
                >
                  &larr; Back to Application Main Menu
                </a>
              )}
            {(!AppTermData.AppTermDataEntity ||
              AppTermData.AppTermDataEntity.terms.length === 1) && <div></div>}
            <SignOutButton />
          </div>
        </ContentWrapper>
      </nav>
    </>
  );
};

interface RowProps {
  label: string;
  value: React.ReactNode;
}

const ApplicationSummaryRow: React.FC<RowProps> = (props) => {
  const { label, value } = props;

  return (
    <div className="ApplicationSummaryRow">
      <Typography.Label>{label}</Typography.Label>
      <div>{value}</div>
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    AppTermData: {
      AppTermDataEntity: state.applicationInfo.ApplicationTermsData.entity,
      loading: state.applicationInfo.ApplicationTermsData.loading,
      error: state.applicationInfo.ApplicationTermsData.error,
    },
  };
};

const mapDispatch = {
  removeTermSelected,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(ApplicationFooter);
