import './Icons.css';

import React from 'react';
export enum colorNames
{
  'primary-blue',
  'primary-yellow',
  'secondary-blue',
  'secondary-yellow',
  'secondary-teal',
  'dark-gray',
  'medium-gray',
  'light-gray',
  'accent-green',
  'accent-orange',
  'accent-blue',
  'ftw-red',
  'ftw-light-red',
  'ftw-light-yellow'
} 

export interface Props {
  icon: string;
  size?: string;
  color?:
    | 'primary-blue'
    | 'primary-yellow'
    | 'secondary-blue'
    | 'secondary-yellow'
    | 'secondary-teal'
    | 'dark-gray'
    | 'medium-gray'
    | 'light-gray'
    | 'accent-green'
    | 'accent-orange'
    | 'accent-blue'
    | 'ftw-red'
    | 'ftw-light-red'
    | 'ftw-light-yellow';
}

const Icons: React.FC<Props> = (props) => {
  const { icon, color = 'primary-blue', size = '1.5rem' } = props;

  return (
    <div className={`Icon Icon--${color}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        viewBox="0 0 24 24"
        width={size}
      >
        {icon === 'phone' && (
          <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
        )}
        {icon === 'mail' && <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />}
        {icon === 'download' && (
          <path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z" />
        )}
        {icon === 'globe' && (
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
        )}
        {icon === 'map' && (
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
        )}
        {icon === 'food' && (
          <path d="M8.1 13.34l2.83-2.83L3.91 3.5c-1.56 1.56-1.56 4.09 0 5.66l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38 1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27L3.7 19.87l1.41 1.41L12 14.41l6.88 6.88 1.41-1.41L13.41 13l1.47-1.47z" />
        )}
        {icon === 'home' && (
          <path d="M12,3L6,7.58V6H4v3.11L1,11.4l1.21,1.59L4,11.62V21h7v-6h2v6h7v-9.38l1.79,1.36L23,11.4L12,3z M10,1c0,1.66-1.34,3-3,3 C6.45,4,6,4.45,6,5H4c0-1.66,1.34-3,3-3c0.55,0,1-0.45,1-1H10z" />
        )}
        {icon === 'lock' && (
          <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
        )}
      </svg>
    </div>
  );
};

export default Icons;
