import "./SignOutButton.css";

import React from "react";

import * as Typography from "./typography";

const SignOutButton: React.FC = () => {
  return (
    <a href={process.env.REACT_APP_SIGN_OUT_URL as string} className="SignOutButton">
      <Typography.Label>Sign Out</Typography.Label>
    </a>
  );
};

export default SignOutButton;
