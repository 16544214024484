export enum PageName {
  applicationAccepted,
  applicationCanceled,
  applicationDenied,
  applicationPending,
  missedDeadline,
  payDeposit,
  selectTerm,
  welcome,
  noApplications,
}
