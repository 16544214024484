import "./ApplicationDenied.css";

import React from "react";

import * as Typography from "../components/typography";
import MessagingRow from "../components/MessagingRow";
import PageBorder from "../components/PageBorder";
import ApplicationFooter from "../components/ApplicationFooter";
import { TermData as Props } from "../models/TermData";

export const ApplicationDenied: React.FC<Props> = (props) => {
  const { studentPortalUrl, appealDenialText, appealDenialUrl } = props;

  return (
    <>
      <PageBorder color="teal" />
      <MessagingRow
        messaging={
          <div className="ApplicationDenied-messaging">
            <Typography.SecondaryHeading>
              Unfortunately, your application to UC Merced has been denied.
            </Typography.SecondaryHeading>
            <Typography.Paragraph>
              Visit the&nbsp;
              <a className="ApplicationDenied-link" href={appealDenialUrl}>
                {appealDenialText}
              </a>
              &nbsp;to appeal this decision.
            </Typography.Paragraph>
            <Typography.SmallText>
              <a className="ApplicationDenied-link" href={studentPortalUrl}>
                ← Back to UC Merced Connect
              </a>
            </Typography.SmallText>
          </div>
        }
      />
      <ApplicationFooter {...props} />
    </>
  );
};
