import "./NoApplications.css";

import HeroImageLarge from "../assets/images/banner-1.jpg";
import HeroImageMedium from "../assets/images/banner-1@0.5x.jpg";
import HeroImageSmall from "../assets/images/banner-1@0.25x.jpg";

import React from "react";

import * as Typography from "../components/typography";
import MessagingRow from "../components/MessagingRow";
import ApplicationFooter from "../components/ApplicationFooter";
import { TermData as Props } from "../models/TermData";

const NoApplications: React.FC<Props> = (props) => {
  return (
    <>
      <img
        className="ApplicationPending-heroImage"
        srcSet={`${HeroImageLarge} 3600w, ${HeroImageMedium} 1800w, ${HeroImageSmall} 900w`}
        sizes="(max-width: 600px) 900px, (max-width: 1200px) 1800px, 3600px"
        alt="Application Pending"
      />
      <MessagingRow
        messaging={
          <div className="ApplicationAccepted-messaging">
            <Typography.PrimaryHeading>
              You do not have a current valid undergraduate application.
            </Typography.PrimaryHeading>
          </div>
        }
        callToAction={true}
      />
      <ApplicationFooter noApplications={true} {...props} />
    </>
  );
};

export default NoApplications;
