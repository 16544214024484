import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/store";

type Props = PropsFromRedux & {
  showLoading: boolean;
  spinnerOnly: boolean;
  numberOfDivs: number;
  classBlock?: string;
  classSpinner?: string;
};

const LoadingWheel = (props: Props) => {
  const { showLoading, spinnerOnly = true, numberOfDivs = 4 } = props;
  const arrLength = [...Array.from(Array(numberOfDivs).keys())];

  const divs = arrLength.map((k, index) => {
    return <div key={index}></div>;
  });
  const withSpinner = () => {
    return (
      <div
        className={props.classBlock ? props.classBlock : "LoadingBlock"}
        style={{ zIndex: 9999 }}
      >
        <div
          className={
            props.classSpinner ? props.classSpinner : "LoadingBlock-spinner"
          }
        >
          {divs}
        </div>
      </div>
    );
  };
  const withoutSpinner = () => {
    return (
      <div
        className={
          props.classSpinner ? props.classSpinner : "LoadingBlock-spinner"
        }
        style={{ zIndex: 9999 }}
      >
        {divs}
      </div>
    );
  };
  return (
    <React.Fragment>
      {showLoading && (spinnerOnly ? withoutSpinner() : withSpinner())}
    </React.Fragment>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatch = {};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(LoadingWheel);
