import "./MissedSIRDeadline.css";

import React, { useEffect } from "react";

import * as Typography from "../components/typography";
import MessagingRow from "../components/MessagingRow";
import PageBorder from "../components/PageBorder";
import ApplicationFooter from "../components/ApplicationFooter";
import { Button } from "../components/Button";
import Icons from "../components/Icons";
import { openNewWindow } from "../utility/UtilityFunctions";

import { TermData as Props } from "../models/TermData";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/store";
import { fetchAdmissionLetter } from "../store/reducers/applicationInfo";
import { toastr } from "react-redux-toastr";

type CombinedProps = PropsFromRedux & Props & {};

const MissedSIRDeadline: React.FC<CombinedProps> = (props) => {
  const {
    appealLateSirUrl,
    admissionsLetterUrl,
    AdmissionLetterData,
    fetchAdmissionLetter,
  } = props;

  const getAdmissionLetter = (url: string) => {
    fetchAdmissionLetter(url);
  };
  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    return () => {
      // Side-effect cleanup...
      if (AdmissionLetterData.error) {
        toastr.error(
          AdmissionLetterData.error.title,
          AdmissionLetterData.error.message
        );
      }
    };
  }, [
    AdmissionLetterData.AdmissionLetterResponse,
    AdmissionLetterData.loading,
    AdmissionLetterData.error,
  ]);
  return (
    <>
      <PageBorder color="yellow" />
      <MessagingRow
        messaging={
          <div className="MissedSIRDeadline-messaging">
            <Typography.PrimaryHeading>
              Your application was accepted, but you've missed the Statement of
              Intent to Register (SIR) deadline.
            </Typography.PrimaryHeading>
            <Typography.Paragraph>
              You can appeal to submit a late Statement of Intent to Register.
            </Typography.Paragraph>
          </div>
        }
        callToAction={
          <>
            <div className="MissedSIRDeadline-actions">
              <Button
                onClick={() => openNewWindow(appealLateSirUrl)}
                backgroundColor="yellow"
              >
                Appeal to Submit Late SIR
              </Button>
            </div>
            {admissionsLetterUrl && (
              <>
                <Button
                  loading={
                    AdmissionLetterData.loading === "pending" ? "true" : "false"
                  }
                  onClick={() => getAdmissionLetter(admissionsLetterUrl)}
                >
                  <Icons icon="download" color="secondary-teal" />
                  Download Admissions Letter
                </Button>
                <div style={{ marginBottom: "5px" }}></div>
              </>
            )}
          </>
        }
      />
      <ApplicationFooter {...props} />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    AdmissionLetterData: {
      AdmissionLetterResponse:
        state.applicationInfo.DownloadAdmissionLetter.entity,
      loading: state.applicationInfo.DownloadAdmissionLetter.loading,
      error: state.applicationInfo.DownloadAdmissionLetter.error,
    },
  };
};

const mapDispatch = {
  fetchAdmissionLetter,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(MissedSIRDeadline);
