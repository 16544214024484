import "./ApplicationAccepted.css";

import HeroImageLarge from "../assets/images/banner-2.jpg";
import HeroImageMedium from "../assets/images/banner-2@0.5x.jpg";
import HeroImageSmall from "../assets/images/banner-2@0.25x.jpg";

import React, { useEffect } from "react";

import * as Typography from "../components/typography";
import MessagingRow from "../components/MessagingRow";
import ApplicationFooter from "../components/ApplicationFooter";
import { Button } from "../components/Button";
import Icons from "../components/Icons";
import { TermData as Props } from "../models/TermData";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/store";
import {
  declineOfferPost,
  acceptOfferPost,
  payDepositPost,
  fetchAdmissionLetter,
} from "../store/reducers/applicationInfo";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import { openNewWindow } from "../utility/UtilityFunctions";
import LoadingWheel from "../components/LoadingWheel";
import {USDConfetti} from "../components/Confetti";

type CombinedProps = PropsFromRedux & Props & {};

const ApplicationAccepted: React.FC<CombinedProps> = (props) => {
  const {
    paymentState,
    hasFinancialAidPackage = true,
    financialAidDetailsUrl,
    sirDate,
    sirFee,
    depositWaiverUrl,
    admissionTerm,
    declineOfferPost,
    acceptOfferPost,
    admissionsLetterUrl,
    AcceptOfferData,
    DeclineOfferData,
    termcode,
    fetchAdmissionLetter,
    payDepositPost,
    PayDepositData,
    AdmissionLetterData,
    sirInstructionsText,
    sirInstructionsUrl,
  } = props;
  const navigate = useNavigate();

  const [showDeclineVerification, setShowDeclineVerification] =
    React.useState(false);

  const declineOffer = (termcode: string) => {
    declineOfferPost(termcode);
  };

  const acceptOffer = (termcode: string) => {
    acceptOfferPost(termcode);
  };
  const payDepositSubmit = (termcode: string) => {
    payDepositPost(termcode);
  };
  const getPacificDate = () => {
    return new Date(sirDate).toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    });
  };

  const getAdmissionLetter = (url: string) => {
    fetchAdmissionLetter(url);
  };
  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    // Side-effect...

    if (PayDepositData.error) {
      toastr.error(PayDepositData.error.title, PayDepositData.error.message);
    }

    if (AcceptOfferData.error) {
      toastr.error(AcceptOfferData.error.title, AcceptOfferData.error.message);
    }

    if (AdmissionLetterData.error) {
      toastr.error(
        AdmissionLetterData.error.title,
        AdmissionLetterData.error.message
      );
    }

    if (DeclineOfferData.error) {
      toastr.error(
        DeclineOfferData.error.title,
        DeclineOfferData.error.message
      );
    }
    if (DeclineOfferData.DeclineResponse) {
      if (DeclineOfferData.DeclineResponse.declineSurveyUrl) {
        openNewWindow(DeclineOfferData.DeclineResponse.declineSurveyUrl);
      }
      navigate("/" + DeclineOfferData.DeclineResponse.page);
    }

    if (AcceptOfferData.AcceptResponse && AcceptOfferData.AcceptResponse.page) {
      navigate("/" + AcceptOfferData.AcceptResponse.page);
    }

    if (
      PayDepositData.PayDepositResponse &&
      PayDepositData.PayDepositResponse.page
    ) {
      navigate("/" + PayDepositData.PayDepositResponse.page);
    }

    return () => {
      // Side-effect cleanup...
    };
  }, [
    AcceptOfferData.AcceptResponse,
    AcceptOfferData.loading,
    AcceptOfferData.error,
    DeclineOfferData.DeclineResponse,
    DeclineOfferData.loading,
    DeclineOfferData.error,
    PayDepositData.PayDepositResponse,
    PayDepositData.loading,
    PayDepositData.error,
    AdmissionLetterData.AdmissionLetterResponse,
    AdmissionLetterData.loading,
    AdmissionLetterData.error,
    navigate,
  ]);

  const renderPaymentStateMainText = () => {
    switch (paymentState) {
      case "required":
      case "deferred":
        return (
          <Typography.PrimaryHeading>
            Congratulations! You are admitted to UC Merced for {admissionTerm}!
          </Typography.PrimaryHeading>
        );

      case "incomplete":
        return (
          <Typography.PrimaryHeading>
            Your Statement of Intent to Register (SIR) is incomplete pending
            receipt of your ${sirFee} deposit.
          </Typography.PrimaryHeading>
        );
    }
  };

  const renderPaymentStateSubText = () => {
    switch (paymentState) {
      case "deferred":
        return (
          <Typography.Paragraph>
            <strong>Your deposit has been deferred</strong> — you don't owe
            anything at this time. You must submit your{" "}
            <strong>Statement of Intent to Register (SIR)</strong> by{" "}
            <strong>
              {new Date(getPacificDate()).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}{" "}
              11:59 PM Pacific Time
            </strong>
            .
          </Typography.Paragraph>
        );
      case "required":
        return (
          <Typography.Paragraph>
            In order to process your{" "}
            <strong>Statement of Intent to Register (SIR)</strong>, you must pay
            a non-refundable ${sirFee} deposit by{" "}
            <strong>
              {new Date(getPacificDate()).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}{" "}
              11:59 PM Pacific Time
            </strong>
            .
          </Typography.Paragraph>
        );
      case "incomplete":
        return (
          <Typography.Paragraph>
            Please click the "Pay ${sirFee} Deposit" button on this page to
            submit your payment and complete your SIR. You must pay your SIR
            deposit by{" "}
            <strong>
              {new Date(getPacificDate()).toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}{" "}
              11:59 PM Pacific Time
            </strong>{" "}
            to complete your SIR. If you already sent a check, paid online, or
            requested a waiver, your SIR status may take 7 to 10 days to update
            and show as complete. &nbsp;{" "}
            <a
              className="PaymentStep-waiverLink SmallText"
              href={depositWaiverUrl}
            >
              Request Deferment
            </a>
          </Typography.Paragraph>
        );
    }
  };

  const renderPaymentStateButton = () => {
    switch (paymentState) {
      case "incomplete":
      case "required":
        return (
          <>
            <div style={{ position: "relative" }}>
              {/* eslint-disable-next-line */}
              <a onClick={() => payDepositSubmit(termcode)}>
                <Button
                  backgroundColor="green"
                  size="large"
                  disabled={PayDepositData.loading === "pending"}
                >
                  Pay ${sirFee} Deposit
                </Button>
              </a>
              <LoadingWheel
                numberOfDivs={3}
                showLoading={PayDepositData.loading === "pending"}
                spinnerOnly={true}
              />
            </div>
          </>
        );
      case "deferred":
        return (
          <div style={{ position: "relative" }}>
            <Button
              backgroundColor="green"
              style={{ textAlign: "center" }}
              size="large"
              onClick={() => acceptOffer(termcode)}
              disabled={AcceptOfferData.loading === "pending"}
            >
              Accept Admission Offer
            </Button>
            <LoadingWheel
              numberOfDivs={3}
              showLoading={AcceptOfferData.loading === "pending"}
              spinnerOnly={true}
            />
          </div>
        );
    }
  };
  return (
    <>
      <img
        className="ApplicationAccepted-heroImage"
        srcSet={`${HeroImageLarge} 3601w, ${HeroImageMedium} 1800w, ${HeroImageSmall} 900w`}
        sizes="(max-width: 600px) 900px, (max-width: 1200px) 1800px, 3601px"
        alt="Welcome to UC Merced!"
      />
      <USDConfetti />
      <MessagingRow
        messaging={
          <div className="ApplicationAccepted-messaging">
            {renderPaymentStateMainText()}

            {renderPaymentStateSubText()}

            <ul className="tmp-content-styling">
              <li>
                Download your admission letter, review your conditions of admission and accept your admission offer to
                become a Bobcat!
              </li>
              <li>
                <a href="https://connect.ucmerced.edu/student/live/_/housing_home">Apply for housing</a>.
              </li>
              <li>
                <a href="https://financialaid.ucmerced.edu/betterfafsa-cadaa">Apply for financial aid</a> by May 2,
                2024 to be considered for all available aid. Include our school code on your application, 041271. If
                you've already applied for financial aid, you can view your award at <a
                  href="https://studentssb-prod.ec.ucmerced.edu/StudentSelfService/ssb/studentCommonDashboard">My
                Financial Aid</a> beginning in mid-April.
              </li>
              <li>
                Explore UC Merced and attend an <a href="https://admitted.ucmerced.edu/events">upcoming admissions
                event</a>.
              </li>
              <li>
                Report any D, F, W, NP, or NC grade, as well as any changes to your schedule from what you indicated on
                your application, in <a href="https://ucmerced.tfaforms.net/218094">MyCourseUpdates</a>.
              </li>
              <li>
                Visit your <a href="https://connect.ucmerced.edu/student/desktopchecklist/index">MyChecklist</a> for
                critical next steps to enrolling at UC Merced.
              </li>
            </ul>
          </div>
        }
        callToAction={
          <>
            {showDeclineVerification ? (
                <div className="ApplicationAccepted-actions">
                  <Typography.SmallText>
                    Are you sure you want to decline your offer to attend UC
                    Merced? <strong>This action cannot be undone.</strong>
                  </Typography.SmallText>
                  <div style={{position: "relative"}}>
                    <Button
                        backgroundColor="red"
                        onClick={() => declineOffer(termcode)}
                        disabled={DeclineOfferData.loading === "pending"}
                    >
                      Decline Offer
                    </Button>
                    <LoadingWheel
                        numberOfDivs={3}
                        showLoading={DeclineOfferData.loading === "pending"}
                        spinnerOnly={true}
                    />
                  </div>
                  <Typography.SmallText centered>
                    <button
                        className="ApplicationAccepted-declineLink"
                        onClick={() => setShowDeclineVerification(false)}
                    >
                    Nevermind
                  </button>
                </Typography.SmallText>
              </div>
            ) : (
              <>
                <div className="ApplicationAccepted-actions">
                  {renderPaymentStateButton()}
                  {paymentState !== "incomplete" && (
                    <Typography.SmallText centered>
                      or{" "}
                      <button
                        className="ApplicationAccepted-declineLink"
                        onClick={() => setShowDeclineVerification(true)}
                      >
                        Decline Admission Offer
                      </button>
                    </Typography.SmallText>
                  )}
                </div>

                {admissionsLetterUrl && (
                  <>
                    <Button
                      loading={
                        AdmissionLetterData.loading === "pending"
                          ? "true"
                          : "false"
                      }
                      onClick={() => getAdmissionLetter(admissionsLetterUrl)}
                    >
                      <Icons icon="download" color="secondary-teal" />
                      Download Admissions Letter
                    </Button>
                    <div style={{ marginBottom: "5px" }}></div>
                  </>
                )}
                <a href={sirInstructionsUrl} target={"_blank"} rel="noreferrer">
                  <Button type="button">
                    <Icons icon="globe" color="secondary-teal" />
                    {sirInstructionsText}
                  </Button>
                </a>
              </>
            )}
          </>
        }
      />
      <ApplicationFooter {...props} showTuitionAndWue={true} />
    </>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    AppTermData: {
      AppTermDataEntity: state.applicationInfo.ApplicationTermsData.entity,
      loading: state.applicationInfo.ApplicationTermsData.loading,
      error: state.applicationInfo.ApplicationTermsData.error,
    },
    DeclineOfferData: {
      DeclineResponse: state.applicationInfo.DeclineOffer.entity,
      loading: state.applicationInfo.DeclineOffer.loading,
      error: state.applicationInfo.DeclineOffer.error,
    },
    AcceptOfferData: {
      AcceptResponse: state.applicationInfo.AcceptOffer.entity,
      loading: state.applicationInfo.AcceptOffer.loading,
      error: state.applicationInfo.AcceptOffer.error,
    },
    PayDepositData: {
      PayDepositResponse: state.applicationInfo.payDeposit.entity,
      loading: state.applicationInfo.payDeposit.loading,
      error: state.applicationInfo.payDeposit.error,
    },
    AdmissionLetterData: {
      AdmissionLetterResponse:
        state.applicationInfo.DownloadAdmissionLetter.entity,
      loading: state.applicationInfo.DownloadAdmissionLetter.loading,
      error: state.applicationInfo.DownloadAdmissionLetter.error,
    },
  };
};

const mapDispatch = {
  declineOfferPost,
  acceptOfferPost,
  payDepositPost,
  fetchAdmissionLetter,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(ApplicationAccepted);
