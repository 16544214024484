import "./PageBorder.css";

import React from "react";

interface Props {
  color?: "yellow" | "teal" | "red";
}

const PageBorder: React.FC<Props> = (props) => {
  const { color = "teal" } = props;

  return <div className={"PageBorder is-" + color}></div>;
};

export default PageBorder;
