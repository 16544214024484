import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import sir from "../../apis/api";
import { ErrorToastr } from "../../utility/ErrorToastr";
import { TermData, ApplicationTerms } from "../../models/TermData";

export const fetchAllApplications = createAsyncThunk<ApplicationTerms>(
  "allApplications",
  async (_, thunkApi) => {
    try {
      const response = await sir.get("/applications", {
        withCredentials: true,
      });

      if (typeof response.data == "object") {
        return response.data as ApplicationTerms;
      } else {
        throw new Error("Error - " + response.data);
      }
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

export const payDepositPost = createAsyncThunk<TermData, string>(
  "payDeposit",
  async (termcode: string, thunkApi) => {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("termcode", termcode);
      const response = await sir.post("/payDeposit", bodyFormData, {
        withCredentials: true,
      });

      if (typeof response.data == "object") {
        return response.data as TermData;
      } else {
        throw new Error("Error - " + response.data);
      }
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

export const declineOfferPost = createAsyncThunk<TermData, string>(
  "declineOffer",
  async (termcode: string, thunkApi) => {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("termcode", termcode);
      const response = await sir.post("/declineOffer", bodyFormData, {
        withCredentials: true,
      });

      if (typeof response.data == "object") {
        let returnData = response.data as TermData;
        returnData.termcode = termcode;
        return returnData;
      } else {
        throw new Error("Error - " + response.data);
      }
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

export const cancelApplicationPost = createAsyncThunk<TermData, string>(
  "cancelApplication",
  async (termcode: string, thunkApi) => {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("termcode", termcode);
      const response = await sir.post("/cancelApplication", bodyFormData, {
        withCredentials: true,
      });

      if (typeof response.data == "object") {
        let returnData = response.data as TermData;
        returnData.termcode = termcode;
        return returnData;
      } else {
        throw new Error("Error - " + response.data);
      }
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

export const cancelSirPost = createAsyncThunk<TermData, string>(
  "cancelSir",
  async (termcode: string, thunkApi) => {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("termcode", termcode);
      const response = await sir.post("/cancelSir", bodyFormData, {
        withCredentials: true,
      });

      if (typeof response.data == "object") {
        let returnData = response.data as TermData;
        returnData.termcode = termcode;
        return returnData;
      } else {
        throw new Error("Error - " + response.data);
      }
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

export const acceptOfferPost = createAsyncThunk<TermData, string>(
  "acceptOffer",
  async (termcode: string, thunkApi) => {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("termcode", termcode);
      const response = await sir.post("/acceptOffer", bodyFormData, {
        withCredentials: true,
      });

      if (typeof response.data == "object") {
        let returnData = response.data as TermData;
        returnData.termcode = termcode;
        return returnData;
      } else {
        throw new Error("Error - " + response.data);
      }
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

interface DownloadResponse {
  response: any;
}
export const fetchAdmissionLetter = createAsyncThunk<DownloadResponse, string>(
  "downloadAdmissionLetter",
  async (downloadUrl: string, thunkApi) => {
    try {
      const response = await sir.get(downloadUrl, {
        responseType: "blob",
        withCredentials: true,
      });

      const responseData: DownloadResponse = {
        response: window.URL.createObjectURL(response.data),
      };
      const link = document.createElement("a");
      link.href = responseData.response;
      link.setAttribute("download", "AdmissionLetter.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      return responseData;
    } catch (err: any) {
      let error: AxiosError = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      const meta = { errorMessage: err.message, status: error.response.status };
      const response = { responseMessage: error.response.data, meta: meta };
      return thunkApi.rejectWithValue(response);
    }
  }
);

interface ApplicationInfoState {
  SelectedTerm: TermData | null;
  ApplicationTermsData: {
    error: ErrorToastr | null | undefined;
    loading: "idle" | "pending" | "succeeded" | "failed";
    entity: ApplicationTerms | null;
  };
  DeclineOffer: {
    error: ErrorToastr | null | undefined;
    loading: "idle" | "pending" | "succeeded" | "failed";
    entity: TermData | null;
  };
  CancelApplication: {
    error: ErrorToastr | null | undefined;
    loading: "idle" | "pending" | "succeeded" | "failed";
    entity: TermData | null;
  };
  CancelSir: {
    error: ErrorToastr | null | undefined;
    loading: "idle" | "pending" | "succeeded" | "failed";
    entity: TermData | null;
  };
  AcceptOffer: {
    error: ErrorToastr | null | undefined;
    loading: "idle" | "pending" | "succeeded" | "failed";
    entity: TermData | null;
  };
  payDeposit: {
    error: ErrorToastr | null | undefined;
    loading: "idle" | "pending" | "succeeded" | "failed";
    entity: TermData | null;
  };
  DownloadAdmissionLetter: {
    error: ErrorToastr | null | undefined;
    loading: "idle" | "pending" | "succeeded" | "failed";
    entity: string | null;
  };
}

const applicationInfoSlice = createSlice({
  name: "ApplicationInfo",
  initialState: {
    SelectedTerm: null,
    ApplicationTermsData: {
      entity: null,
      loading: "idle",
      error: null,
    },
    DeclineOffer: {
      entity: null,
      loading: "idle",
      error: null,
    },
    CancelApplication: {
      entity: null,
      loading: "idle",
      error: null,
    },
    CancelSir: {
      entity: null,
      loading: "idle",
      error: null,
    },
    AcceptOffer: {
      entity: null,
      loading: "idle",
      error: null,
    },
    payDeposit: {
      entity: null,
      loading: "idle",
      error: null,
    },
    DownloadAdmissionLetter: {
      entity: null,
      loading: "idle",
      error: null,
    },
  } as ApplicationInfoState,

  reducers: {
    selectTerm: (state, action: PayloadAction<TermData>) => {
      state.SelectedTerm = action.payload;
    },
    removeTermSelected: (state, action) => {
      state.SelectedTerm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchAllApplications.pending, (state, action) => {
        if (state.ApplicationTermsData.loading !== "pending") {
          state.ApplicationTermsData.loading = "pending";
        }
      })
      .addCase(
        fetchAllApplications.fulfilled,
        (state, action: PayloadAction<ApplicationTerms>) => {
          state.ApplicationTermsData.loading = "succeeded";
          state.ApplicationTermsData.entity = action.payload;
          if (
            state.ApplicationTermsData.entity.terms &&
            state.ApplicationTermsData.entity.terms.length === 1
          ) {
            state.SelectedTerm = state.ApplicationTermsData.entity.terms[0];
          }
        }
      )
      .addCase(fetchAllApplications.rejected, (state: any, action: any) => {
        if (action.payload) {
          const errorObj: ErrorToastr = {
            title: "!Error - " + action.payload.meta.status,
            message:
              action.payload && action.payload.responseMessage.message
                ? action.payload.responseMessage.message
                : action.payload.meta.errorMessage,
          };
          state.ApplicationTermsData.error = errorObj;
        } else {
          const errorObj: ErrorToastr = {
            title: "!Unknown Error",
            message: "Contact Help Desk - " + action.error.message,
          };
          state.ApplicationTermsData.error = errorObj;
        }

        state.ApplicationTermsData.loading = "failed";
      })

      .addCase(declineOfferPost.pending, (state, action) => {
        if (state.DeclineOffer.loading !== "pending") {
          state.DeclineOffer.loading = "pending";
        }
      })
      .addCase(
        declineOfferPost.fulfilled,
        (state, action: PayloadAction<TermData>) => {
          state.DeclineOffer.loading = "succeeded";
          state.DeclineOffer.entity = action.payload;
          if (state.SelectedTerm) {
            state.SelectedTerm = { ...state.SelectedTerm, ...action.payload };
          }

          const TermFound = state.ApplicationTermsData.entity?.terms.find(
            (term) => term.termcode === action.payload.termcode
          );
          if (TermFound) {
            (state.ApplicationTermsData.entity as ApplicationTerms).terms = (
              state.ApplicationTermsData.entity as ApplicationTerms
            ).terms.map((item, index) => {
              if (item.termcode === TermFound.termcode) {
                return state.SelectedTerm as TermData;
              }
              return item;
            });
          }
        }
      )
      .addCase(declineOfferPost.rejected, (state: any, action: any) => {
        if (action.payload) {
          const errorObj: ErrorToastr = {
            title: "!Error - " + action.payload.meta.status,
            message:
              action.payload && action.payload.responseMessage.message
                ? action.payload.responseMessage.message
                : action.payload.meta.errorMessage,
          };
          state.DeclineOffer.error = errorObj;
        } else {
          const errorObj: ErrorToastr = {
            title: "!Unknown Error",
            message: "Contact Help Desk - " + action.error.message,
          };
          state.DeclineOffer.error = errorObj;
        }

        state.DeclineOffer.loading = "failed";
      })

      .addCase(cancelSirPost.pending, (state, action) => {
        if (state.CancelSir.loading !== "pending") {
          state.CancelSir.loading = "pending";
        }
      })
      .addCase(
        cancelSirPost.fulfilled,
        (state, action: PayloadAction<TermData>) => {
          debugger;
          state.CancelSir.loading = "succeeded";
          state.CancelSir.entity = action.payload;
          if (state.SelectedTerm) {
            state.SelectedTerm = { ...state.SelectedTerm, ...action.payload };
          }

          const TermFound = state.ApplicationTermsData.entity?.terms.find(
            (term) => term.termcode === action.payload.termcode
          );
          if (TermFound) {
            (state.ApplicationTermsData.entity as ApplicationTerms).terms = (
              state.ApplicationTermsData.entity as ApplicationTerms
            ).terms.map((item, index) => {
              if (item.termcode === TermFound.termcode) {
                return state.SelectedTerm as TermData;
              }
              return item;
            });
          }
        }
      )
      .addCase(cancelSirPost.rejected, (state: any, action: any) => {
        if (action.payload) {
          const errorObj: ErrorToastr = {
            title: "!Error - " + action.payload.meta.status,
            message:
              action.payload && action.payload.responseMessage.message
                ? action.payload.responseMessage.message
                : action.payload.meta.errorMessage,
          };
          state.CancelSir.error = errorObj;
        } else {
          const errorObj: ErrorToastr = {
            title: "!Unknown Error",
            message: "Contact Help Desk - " + action.error.message,
          };
          state.CancelSir.error = errorObj;
        }

        state.CancelSir.loading = "failed";
      })

      .addCase(cancelApplicationPost.pending, (state, action) => {
        if (state.CancelApplication.loading !== "pending") {
          state.CancelApplication.loading = "pending";
        }
      })
      .addCase(
        cancelApplicationPost.fulfilled,
        (state, action: PayloadAction<TermData>) => {
          state.CancelApplication.loading = "succeeded";
          state.CancelApplication.entity = action.payload;
          if (state.SelectedTerm) {
            state.SelectedTerm = { ...state.SelectedTerm, ...action.payload };
          }

          const TermFound = state.ApplicationTermsData.entity?.terms.find(
            (term) => term.termcode === action.payload.termcode
          );
          if (TermFound) {
            (state.ApplicationTermsData.entity as ApplicationTerms).terms = (
              state.ApplicationTermsData.entity as ApplicationTerms
            ).terms.map((item, index) => {
              if (item.termcode === TermFound.termcode) {
                return state.SelectedTerm as TermData;
              }
              return item;
            });
          }
        }
      )
      .addCase(cancelApplicationPost.rejected, (state: any, action: any) => {
        if (action.payload) {
          const errorObj: ErrorToastr = {
            title: "!Error - " + action.payload.meta.status,
            message:
              action.payload && action.payload.responseMessage.message
                ? action.payload.responseMessage.message
                : action.payload.meta.errorMessage,
          };
          state.CancelApplication.error = errorObj;
        } else {
          const errorObj: ErrorToastr = {
            title: "!Unknown Error",
            message: "Contact Help Desk - " + action.error.message,
          };
          state.CancelApplication.error = errorObj;
        }

        state.CancelApplication.loading = "failed";
      })

      .addCase(payDepositPost.pending, (state, action) => {
        if (state.payDeposit.loading !== "pending") {
          state.payDeposit.loading = "pending";
        }
      })
      .addCase(
        payDepositPost.fulfilled,
        (state, action: PayloadAction<TermData>) => {
          state.payDeposit.loading = "succeeded";
          state.payDeposit.entity = action.payload;
        }
      )
      .addCase(payDepositPost.rejected, (state: any, action: any) => {
        if (action.payload) {
          const errorObj: ErrorToastr = {
            title: "!Error - " + action.payload.meta.status,
            message:
              action.payload && action.payload.responseMessage.message
                ? action.payload.responseMessage.message
                : action.payload.meta.errorMessage,
          };
          state.payDeposit.error = errorObj;
        } else {
          const errorObj: ErrorToastr = {
            title: "!Unknown Error",
            message: "Contact Help Desk - " + action.error.message,
          };
          state.payDeposit.error = errorObj;
        }

        state.payDeposit.loading = "failed";
      })

      .addCase(acceptOfferPost.pending, (state, action) => {
        if (state.AcceptOffer.loading !== "pending") {
          state.AcceptOffer.loading = "pending";
        }
      })
      .addCase(
        acceptOfferPost.fulfilled,
        (state, action: PayloadAction<TermData>) => {
          state.AcceptOffer.loading = "succeeded";
          state.AcceptOffer.entity = action.payload;
          if (state.SelectedTerm) {
            state.SelectedTerm = { ...state.SelectedTerm, ...action.payload };
          }

          const TermFound = state.ApplicationTermsData.entity?.terms.find(
            (term) => term.termcode === action.payload.termcode
          );
          if (TermFound) {
            (state.ApplicationTermsData.entity as ApplicationTerms).terms = (
              state.ApplicationTermsData.entity as ApplicationTerms
            ).terms.map((item, index) => {
              if (item.termcode === TermFound.termcode) {
                return state.SelectedTerm as TermData;
              }
              return item;
            });
          }
        }
      )
      .addCase(acceptOfferPost.rejected, (state: any, action: any) => {
        if (action.payload) {
          const errorObj: ErrorToastr = {
            title: "!Error - " + action.payload.meta.status,
            message:
              action.payload && action.payload.responseMessage.message
                ? action.payload.responseMessage.message
                : action.payload.meta.errorMessage,
          };
          state.AcceptOffer.error = errorObj;
        } else {
          const errorObj: ErrorToastr = {
            title: "!Unknown Error",
            message: "Contact Help Desk - " + action.error.message,
          };
          state.AcceptOffer.error = errorObj;
        }

        state.AcceptOffer.loading = "failed";
      })
      .addCase(fetchAdmissionLetter.pending, (state, action) => {
        if (state.DownloadAdmissionLetter.loading !== "pending") {
          state.DownloadAdmissionLetter.loading = "pending";
        }
      })
      .addCase(fetchAdmissionLetter.fulfilled, (state, action) => {
        const response = action.payload.response;

        state.DownloadAdmissionLetter.entity = response;

        state.DownloadAdmissionLetter.loading = "succeeded";
      })
      .addCase(fetchAdmissionLetter.rejected, (state: any, action: any) => {
        if (action.payload) {
          const errorObj: ErrorToastr = {
            title: "!Error - " + action.payload.meta.status,
            message:
              action.payload && action.payload.responseMessage.message
                ? action.payload.responseMessage.message
                : action.payload.meta.errorMessage,
          };
          state.DownloadAdmissionLetter.error = errorObj;
        } else {
          const errorObj: ErrorToastr = {
            title: "!Unknown Error",
            message: "Contact Help Desk - " + action.error.message,
          };
          state.DownloadAdmissionLetter.error = errorObj;
        }

        state.DownloadAdmissionLetter.loading = "failed";
      });
  },
});

export const { removeTermSelected, selectTerm } = applicationInfoSlice.actions;
export default applicationInfoSlice.reducer;
