import "./ApplicationInfoWrapper.scss";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { toastr } from "react-redux-toastr";
import { RootState } from "../store/store";
import { TermData, ApplicationTerms } from "../models/TermData";
import { PageName } from "../utility/Enums";
import { fetchAllApplications } from "../store/reducers/applicationInfo";
import ApplicationAccepted from "../pages/ApplicationAccepted";
import { ApplicationCancelled } from "../pages/ApplicationCancelled";
import TermSelection from "../pages/TermSelection";
import { ApplicationDenied } from "../pages/ApplicationDenied";
import ApplicationPending from "../pages/ApplicationPending";
import MissedSIRDeadline from "../pages/MissedSIRDeadline";
import { PaymentStep } from "../pages/PaymentStep";
import WelcomeStep from "../pages/WelcomeStep";
import NoApplications from "../pages/NoApplications";
import { useNavigate, useParams } from "react-router-dom";
import LoadingWheel from "../components/LoadingWheel";

const pages = {
  applicationAccepted: PageName.applicationAccepted,
  applicationCanceled: PageName.applicationCanceled,
  applicationDenied: PageName.applicationDenied,
  applicationPending: PageName.applicationPending,
  missedDeadline: PageName.missedDeadline,
  payDeposit: PageName.payDeposit,
  selectTerm: PageName.selectTerm,
  welcome: PageName.welcome,
  noApplications: PageName.noApplications,
};

const ApplicationInfoWrapper = (props: PropsFromRedux) => {
  const { page } = useParams();
  const navigate = useNavigate();

  const { AppTermData, CurrentUser, fetchAllApplications, SelectedTerm } =
    props;

  useEffect(() => {
    // Side-effect...

    if (CurrentUser.user && CurrentUser.user.loggedIn) {
      if (AppTermData.error) {
        toastr.error(AppTermData.error.title, AppTermData.error.message);
      }
      if (
        AppTermData.AppTermDataEntity &&
        AppTermData.AppTermDataEntity.terms.length === 1
      ) {
        navigate("/" + AppTermData.AppTermDataEntity.terms[0].page);
      }
      if (
        AppTermData.AppTermDataEntity &&
        AppTermData.AppTermDataEntity.terms.length > 1
      ) {
        navigate("/selectTerm");
      }
      if (!AppTermData.AppTermDataEntity && AppTermData.loading === "idle") {
        fetchAllApplications();
      }

      if (
        AppTermData.AppTermDataEntity &&
        AppTermData.AppTermDataEntity.terms.length === 0
      ) {
        navigate("/noApplications");
      }
    }

    return () => {
      // Side-effect cleanup...
    };
  }, [
    AppTermData.AppTermDataEntity,
    AppTermData.loading,
    AppTermData.error,
    CurrentUser.user,
    fetchAllApplications,
  ]);

  if (!Object.keys(pages).includes(page)) {
    return <></>;
  }

  if (!AppTermData.AppTermDataEntity) {
    return (
      <div className="center">
        <LoadingWheel
          numberOfDivs={4}
          showLoading={AppTermData.loading === "pending"}
          spinnerOnly={false}
        />
      </div>
    );
  }

  const pageToDisplay = pages[page];

  return (
    <React.Fragment>
      {pageToDisplay === PageName.selectTerm && (
        <TermSelection
          {...(AppTermData.AppTermDataEntity as ApplicationTerms)}
        />
      )}
      {pageToDisplay === PageName.applicationAccepted && (
        <ApplicationAccepted {...(SelectedTerm as TermData)} />
      )}
      {pageToDisplay === PageName.applicationCanceled && (
        <ApplicationCancelled {...(SelectedTerm as TermData)} />
      )}
      {pageToDisplay === PageName.applicationDenied && (
        <ApplicationDenied {...(SelectedTerm as TermData)} />
      )}
      {pageToDisplay === PageName.applicationPending && (
        <ApplicationPending {...(SelectedTerm as TermData)} />
      )}
      {pageToDisplay === PageName.missedDeadline && (
        <MissedSIRDeadline {...(SelectedTerm as TermData)} />
      )}
      {pageToDisplay === PageName.payDeposit && (
        <PaymentStep {...(SelectedTerm as TermData)} />
      )}
      {pageToDisplay === PageName.welcome && (
        <WelcomeStep {...(SelectedTerm as TermData)} />
      )}
      {pageToDisplay === PageName.noApplications && (
        <NoApplications {...(SelectedTerm as unknown as TermData)} />
      )}
    </React.Fragment>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    AppTermData: {
      AppTermDataEntity: state.applicationInfo.ApplicationTermsData.entity,
      loading: state.applicationInfo.ApplicationTermsData.loading,
      error: state.applicationInfo.ApplicationTermsData.error,
    },
    CurrentUser: {
      user: state.user.entity,
    },
    SelectedTerm: state.applicationInfo.SelectedTerm,
  };
};

const mapDispatch = {
  fetchAllApplications,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(ApplicationInfoWrapper);
