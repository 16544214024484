import "./AuthenticateWrapper.scss";
import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/store";
import { toastr } from "react-redux-toastr";
import { useNavigate } from "react-router-dom";
import LoadingWheel from "../components/LoadingWheel";
import { fetchCurrentUser } from "../store/reducers/user";

type Props = PropsFromRedux & {
  children?: React.ReactNode;
};

const AuthenticateWrapper = (props: Props) => {
  const { CurrentUser, fetchCurrentUser, children } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (CurrentUser.error) {
      toastr.error(CurrentUser.error.title, CurrentUser.error.message);
    }
    if (!CurrentUser.user && CurrentUser.loading === "idle") {
      fetchCurrentUser();
    }
    if (CurrentUser.user && !CurrentUser.user.loggedIn) {
      window.location.href = process.env.REACT_APP_SIGN_IN_URL;
    }

    return () => {
      // Side-effect cleanup...
    };
  }, [
    CurrentUser.user,
    CurrentUser.loading,
    CurrentUser.error,
    fetchCurrentUser,
    navigate,
  ]);

  if (CurrentUser.user && CurrentUser.user.loggedIn) {
    return <>{children}</>;
  }
  return (
    <div className="center">
      <LoadingWheel
        numberOfDivs={4}
        showLoading={CurrentUser.loading === "pending"}
        spinnerOnly={false}
      />
    </div>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  return {
    CurrentUser: {
      user: state.user.entity,
      loading: state.user.loading,
      error: state.user.error,
    },
  };
};

const mapDispatch = {
  fetchCurrentUser,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(AuthenticateWrapper);
