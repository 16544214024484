import "./MessagingRow.css";

import React from "react";
import ContentWrapper from "./ContentWrapper";

interface Props {
  messaging: React.ReactNode;
  callToAction?: React.ReactNode;
}

const MessagingRow: React.FC<Props> = (props) => {
  const { messaging, callToAction } = props;

  return (
    <ContentWrapper>
      <section
        className={callToAction ? `MessagingRow--withCTA` : "MessagingRow"}
      >
        <div>{messaging}</div>
        {callToAction && <div>{callToAction}</div>}
      </section>
    </ContentWrapper>
  );
};

export default MessagingRow;
