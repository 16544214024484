import "./Button.css";

import React from "react";
import LoadingWheel from "./LoadingWheel";

interface Props {
  /**
   * What background color to use
   */
  backgroundColor?: "none" | "red" | "green" | "teal" | "yellow";
  /**
   * Is the button currently indicating that something is loading?
   */
  loading?: "true" | "false";
  /**
   * How big should the button be?
   */
  size?: "normal" | "large";
  /**
   * Button contents
   */
  children: React.ReactNode;
  /**
   * Optional click handler
   */
  onClick?: () => void;

  type?: "button" | "submit" | "reset" | undefined;

  style?: any;

  disabled?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<Props> = (props) => {
  const {
    backgroundColor = "none",
    size = "normal",
    children,
    disabled,
    loading = "false",
    type = "button",
    style,
  } = props;

  const dropProp = () => {
    let { backgroundColor, ...rest } = props;
    return rest;
  };
  const filterProps = dropProp();
  if (loading === "true") {
    return (
      <button
        type={type}
        className={`Button--loading
      Button--size-${size}`}
        {...style}
        disabled
        {...filterProps}
      >
        <LoadingWheel numberOfDivs={3} showLoading={true} spinnerOnly={true} />
      </button>
    );
  } else {
    return (
      <button
        type={type}
        className={`Button Button--background-${backgroundColor}
      Button--size-${size}`}
        {...style}
        {...filterProps}
      >
        {children}
      </button>
    );
  }
};
