import "./PaymentStep.css";

import BackgroundImageLarge from "../assets/images/banner-2.jpg";
import BackgroundImageMedium from "../assets/images/banner-2@0.5x.jpg";
import BackgroundImageSmall from "../assets/images/banner-2@0.25x.jpg";
import React from "react";

import * as Typography from "../components/typography";
import { Button } from "../components/Button";
import { TermData as Props } from "../models/TermData";
import ModalLayout from "./ModalLayout";

export const PaymentStep: React.FC<Props> = (props) => {
  const { payDepositUrl, studentId, depositWaiverUrl, payParameters } = props;

  const renderPayParameters = payParameters.map((payParameter) => {
    return (
      <input
        type="hidden"
        name={payParameter.formData}
        value={payParameter.formValue}
      />
    );
  });

  return (
    <ModalLayout
      imageSrcSet={`${BackgroundImageLarge} 3600w, ${BackgroundImageMedium} 1800w, ${BackgroundImageSmall} 900w`}
      imageSizes="(max-height: 300px) 900px, (max-height: 600px) 1800w, 3600w"
      imageAlt="Select Term Background"
    >
      <form action={payDepositUrl ?? ""} method="post">
        {renderPayParameters}
        <Typography.Label centered>Payment Options</Typography.Label>
        {/* eslint-disable-next-line */}
        <a>
          <Button size="large" backgroundColor="green" type="submit">
            Pay Online
          </Button>
        </a>
      </form>
      <div>
        Online payments can be made using major credit cards or electronic
        check. Or, you may send a personal check or money order, payable to{" "}
        <strong>UC REGENTS</strong>. Note your UC Merced Student ID number,{" "}
        <strong>{studentId}</strong>, on it and mail it to:
      </div>
      <div>
        <strong>
          University of California, Merced
          <br />
          Campus Cashiering Services
          <br />
          P.O. Box 2450
          <br />
          Merced, CA 95344
        </strong>
      </div>
      <Typography.SmallText centered>
        or{" "}
        <a className="PaymentStep-waiverLink" href={depositWaiverUrl}>
          Request Deferral
        </a>
      </Typography.SmallText>
    </ModalLayout>
  );
};
