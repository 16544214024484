import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import "./App.scss";
import ApplicationInfoWrapper from "./ApplicationInfoWrapper";
import AuthenticateWrapper from "./AuthenticateWrapper";
import store from "../store/store";

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <div>
          <Routes>
            <Route
              path="/"
              element={
                <AuthenticateWrapper>
                  <ApplicationInfoWrapper />
                </AuthenticateWrapper>
              }
            />
            <Route
              path="/:page"
              element={
                <AuthenticateWrapper>
                  <ApplicationInfoWrapper />
                </AuthenticateWrapper>
              }
            />
          </Routes>

          <Provider store={store}>
            <div>
              <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="bottom-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
              />
            </div>
          </Provider>
        </div>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
