import './Label.css';

import React from 'react';

interface Props {
  children: React.ReactNode;
  centered?: boolean;
}

const Label: React.FC<Props> = (props) => {
  const { children, centered = false } = props;

  return (
    <div className="Label" style={{ textAlign: centered ? 'center' : 'left' }}>
      {children}
    </div>
  );
};

export default Label;
